import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import { Footer, InputWithIcon } from "../../components";
import "./Login.css";
import logoImage from "../../assets/images/logo.svg";
import { MdOutlineEmail, MdOutlineLock } from "react-icons/md";

const Login = () => {
  const { t } = useTranslation();
  const { login, loading } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    document.body.classList.add("login-page", "sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return () => {
      document.body.classList.remove("login-page", "sidebar-collapse");
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password }, from);
  };

  return (
    <>
      <div
        className='page-header header-filter'
        style={{
          background:
            "linear-gradient(0deg, rgba(44,44,44,.2), rgba(3,161,224,.6))",
        }}
      >
        <div className='page-header-image'></div>
        <div className='content'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' md='5'>
                <Card className='card-login card-plain'>
                  <Form onSubmit={handleSubmit} className='form'>
                    <CardHeader className='text-center'>
                      <div className='logo-container'>
                        <img alt='Company Logo' src={logoImage}></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputWithIcon
                        type='email'
                        name='email'
                        placeholder={t("login.email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        icon={<MdOutlineEmail />}
                        focusState={{
                          focus: emailFocus,
                          setFocus: setEmailFocus,
                        }}
                      />
                      <InputWithIcon
                        type='password'
                        placeholder={t("login.password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        icon={<MdOutlineLock />}
                        focusState={{
                          focus: passwordFocus,
                          setFocus: setPasswordFocus,
                        }}
                      />
                    </CardBody>
                    <CardFooter className='text-center'>
                      <Button
                        block
                        className='btn-round'
                        color='info'
                        size='lg'
                        type='submit'
                        disabled={loading}
                      >
                        {loading ? t("login.loading") : t("login.login")}
                      </Button>
                    </CardFooter>
                    <div className='pull-left'>
                      <h6>
                        <Link className='link footer-link' to='/register'>
                          {t("login.register")}
                        </Link>
                      </h6>
                    </div>
                    <div className='pull-right'>
                      <h6>
                        <Link
                          className='link footer-link'
                          to='/forgotten-password'
                        >
                          {t("login.forgottenPassword")}
                        </Link>
                      </h6>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Login;
