import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Button,
} from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import customFetch from "../../utils/customFetch";
import { useDashboardContext } from "../../pages/DashboardLayout/DashboardLayout";
import { DashboardPageHeader, FormInput, SubmitBtn } from "../../components";
import { withAuth } from "../../hocs";

const QRCheckin = () => {
  const { t } = useTranslation();
  const { user } = useDashboardContext();
  const navigate = useNavigate();
  const { id: guestId } = useParams();
  const queryClient = useQueryClient();

  const [selectedRestaurant, setSelectedRestaurant] = useState(
    user.restaurants.length === 1 ? user.restaurants[0]._id : ""
  );

  useEffect(() => {
    if (user.restaurants.length === 1) {
      handleSubmit();
    }
  }, [user.restaurants]);

  const handleChange = (e) => {
    setSelectedRestaurant(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    const data = {
      userId: guestId,
      restaurantId: selectedRestaurant,
    };

    try {
      await customFetch.post(`/guest-visits/create-guest-visit`, data);
      toast.success(t("toast.checkinSuccess"));
      queryClient.invalidateQueries({ queryKey: ["guestVisits"] });
      navigate("/dashboard/visits/");
    } catch (error) {
      toast.error(error?.response?.data?.msg || t("toast.errorOccurred"));
    }
  };

  if (user.restaurants.length === 0) {
    return (
      <Container>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='card-profile card-plain'>
              <CardHeader>
                <h4 className='card-title'>{t("unauthorized")}</h4>
              </CardHeader>
              <CardBody>{t("noRestaurants")}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <DashboardPageHeader pageTitle={t("checkin")} />
      <Container>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='card-profile card-plain'>
              <CardHeader>
                <h4 className='card-title'>{t("checkin")}</h4>
              </CardHeader>
              <CardBody>
                {user.restaurants.length > 1 && (
                  <Form onSubmit={handleSubmit}>
                    <div className='form-row'>
                      <label htmlFor='restaurant' className='form-label'>
                        {t("selectRestaurant")}
                      </label>
                      <select
                        className='form-select'
                        id='restaurant'
                        name='restaurant'
                        value={selectedRestaurant}
                        onChange={handleChange}
                        required
                      >
                        <option value=''>
                          {t("selectRestaurantPlaceholder")}
                        </option>
                        {user.restaurants.map((restaurant) => (
                          <option key={restaurant._id} value={restaurant._id}>
                            {restaurant.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <SubmitBtn formBtn>{t("checkin")}</SubmitBtn>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withAuth(QRCheckin);
