import { createContext, useContext } from "react";
import customFetch from "../../utils/customFetch";
import { useQuery } from "@tanstack/react-query";
import GuestVisitsContainer from "../../components/GuestVisitsContainer";
import { useDashboardContext } from "../DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { DashboardPageHeader } from "../../components";

const GuestVisitsQuery = {
  queryKey: ["guestVisits"],
  queryFn: async () => {
    const response = await customFetch.get("/users/open-visit-users");
    return response.data;
  },
};

const GuestVisitContext = createContext();

const GuestVisits = () => {
  const { data, isLoading, isError } = useQuery(GuestVisitsQuery);
  const { activeRestaurant } = useDashboardContext();
  const { t } = useTranslation();

  if (isLoading) {
    return <div>{t("common:loading")}</div>;
  }

  if (isError) {
    return <div>{t("common:error")}</div>;
  }

  const validatedData = data.filter((d) => d.restaurantId === activeRestaurant);

  return (
    <GuestVisitContext.Provider value={{ validatedData }}>
      <DashboardPageHeader pageTitle={t("visits.title")} />
      <GuestVisitsContainer />
    </GuestVisitContext.Provider>
  );
};

export const GuestVisitsContext = () => useContext(GuestVisitContext);
export default GuestVisits;
