import { createContext, useContext } from "react";
import { RewardsContainer, DashboardPageHeader } from "../../components";
import customFetch from "../../utils/customFetch";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

// Define the query for fetching outcomes
const myEntriesQuery = {
  queryKey: ["outcomes"],
  queryFn: async () => {
    const response = await customFetch.get("/outcomes/my-outcomes");
    return response.data;
  },
};

// Create a context for MyRewards
const MyRewardsContext = createContext();

// The MyRewards component
const MyRewards = () => {
  const { t } = useTranslation();
  // Use the useQuery hook to fetch outcomes
  const { data, isLoading, isError } = useQuery(myEntriesQuery);

  // Handle loading and error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading rewards data.</div>;
  }

  // Provide the data via context
  return (
    <>
      <DashboardPageHeader pageTitle={t("myrewards.pageTitle")} />
      <div className='section'>
        <MyRewardsContext.Provider value={{ data }}>
          <RewardsContainer />
        </MyRewardsContext.Provider>
      </div>
    </>
  );
};

// Custom hook to use MyRewardsContext
export const useMyRewardsContext = () => useContext(MyRewardsContext);

export default MyRewards;
