import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import customFetch from "../../utils/customFetch";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import {
  DashboardPageHeader,
  FormInput,
  InputWithIcon,
  SubmitBtn,
  UserCard,
} from "../../components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Container, Row } from "reactstrap";
import { MdEmail } from "react-icons/md";

const RestaurantQueryById = (id) => {
  return {
    queryKey: ["activeRestaurant", id],
    queryFn: async () => {
      const { data } = await customFetch.get(`/restaurants/${id}`);
      return data; // Return the entire data object
    },
  };
};

const addUserToRestaurant = async ({ id, email }) => {
  await customFetch.patch(`users/add-user-to-restaurant/${id}`, { email });
};

const removeUserFromRestaurant = async ({ id, userId }) => {
  await customFetch.patch(`users/remove-user-from-restaurant/${id}`, {
    userId,
  });
};

const RestaurantUsers = () => {
  const { t } = useTranslation();
  const { id } = useParams(); // Use useParams to get the id from the URL
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(RestaurantQueryById(id));
  const [email, setEmail] = useState("");

  const addUserMutation = useMutation(addUserToRestaurant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["activeRestaurant", id]);
      toast.success(t("toast.staffUpdated"));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.msg || t("toast.errorOccurred"));
    },
  });

  const removeUserMutation = useMutation(removeUserFromRestaurant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["activeRestaurant", id]);
      toast.success(t("toast.staffUpdated"));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.msg || t("toast.errorOccurred"));
    },
  });

  const handleAddUser = (e) => {
    e.preventDefault();
    if (email) {
      addUserMutation.mutate({ id, email });
      setEmail("");
    } else {
      toast.error(t("toast.emailRequired"));
    }
  };

  const handleRemoveUser = (userId) => {
    removeUserMutation.mutate({ id, userId });
  };

  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  if (isError) {
    return <div>{t("error")}</div>;
  }

  if (!data || !data.restaurant) {
    return <div>{t("No Restaurants available")}</div>;
  }

  const restaurant = data.restaurant;

  return (
    <>
      <DashboardPageHeader pageTitle={restaurant.name + " - Staff"} />
      <div className='section'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='8'>
              <Card>
                <div className='card-body'>
                  <form onSubmit={handleAddUser} className='form'>
                    <div className='form-center'>
                      <InputWithIcon
                        type='email'
                        name='email'
                        placeholder={t("Enter email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        icon={<MdEmail />}
                        focusState={{
                          focus: true, // Placeholder for focus state management
                          setFocus: () => {}, // Placeholder
                        }}
                      />

                      <SubmitBtn type='submit' intent='add' formBtn>
                        {t("add")}
                      </SubmitBtn>
                    </div>
                  </form>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            {restaurant.users?.map((user) => (
              <UserCard
                key={user._id}
                className='restaurant-user'
                name={user.name}
                lastName={user.lastName}
                email={user.email}
                avatar={user.avatar}
                actions={
                  <button
                    className='btn btn-danger'
                    type='button'
                    onClick={() => handleRemoveUser(user._id)}
                  >
                    {t("Remove")}
                  </button>
                }
              >
                <button
                  type='button'
                  onClick={() => handleRemoveUser(user._id)}
                >
                  x
                </button>
              </UserCard>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RestaurantUsers;
