import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { Loading, LoggedInDropdownScrollNavbar } from "../../components";
import { createContext, useContext, useEffect, useState } from "react";
import customFetch from "../../utils/customFetch";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DashboardContext = createContext();

const DashboardLayout = () => {
  const { t, i18n } = useTranslation(); // Access translation functions and i18n instance
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const isPageLoading = navigation.state === "loading";
  const [showSidebar, setShowSidebar] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [activeRestaurant, setActiveRestaurant] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false); // Track if we should redirect to login after logout
  const [redirectTo, setRedirectTo] = useState(null); // Track original redirect after login

  const selectActiveRestaurant = (newActiveRestaurant) => {
    setActiveRestaurant(newActiveRestaurant);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const logoutUser = async () => {
    setRedirectToLogin(true); // Set to redirect to login after logout
    try {
      await customFetch.get("/auth/logout");
      toast.success(t("toast.logoutSuccessMessage")); // Translate success message
    } catch (error) {
      console.error("Error logging out:", error);
      toast.error(t("toast.logoutErrorMessage")); // Translate error message
    }
  };

  customFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        setIsAuthError(true);
        setRedirectTo(window.location.pathname); // Store the current location to redirect after login
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (isAuthError) {
      logoutUser();
    }
  }, [isAuthError]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await customFetch.get("/users/current-user");
        setUser(data.user);

        // Set active restaurant
        if (
          data.user &&
          data.user.restaurants &&
          data.user.restaurants.length > 0
        ) {
          setActiveRestaurant(data.user.restaurants[0]._id);
        }

        // Redirect to onboarding if needed
        if (data.user && !data.user.isOnboardingComplete) {
          navigate("/onboarding");
        }

        // Set i18n language based on user preference
        if (data.user && data.user.language) {
          i18n.changeLanguage(data.user.language);
        }

        // Redirect after login if there was a redirectTo stored
        if (redirectTo) {
          navigate(redirectTo);
        }
      } catch (error) {
        setError(error);
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [navigate, i18n, redirectTo]);

  useEffect(() => {
    // Handle redirection to login after logout
    if (redirectToLogin) {
      navigate("/login");
      setRedirectToLogin(false); // Reset flag after redirection
    }
  }, [navigate, redirectToLogin]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>{t("errorLoadingData")}</div>; // Translate error message
  }

  return (
    <DashboardContext.Provider
      value={{
        user,
        activeRestaurant,
        logoutUser,
        selectActiveRestaurant,
      }}
    >
      <>
        <LoggedInDropdownScrollNavbar />
        <div className='wrapper'>
          {isPageLoading ? <Loading /> : <Outlet context={{ user }} />}
        </div>
      </>
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
export default DashboardLayout;
