import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import defaultAvatar from "../assets/images/placeholder.jpg";

const GuestVisitCard = ({ _id, email, name, lastName, avatar }) => {
  return (
    <Col>
      <div className='card-profile card'>
        <div className='card-avatar'>
          <img src={avatar ? avatar : defaultAvatar} />
        </div>
        <div className='card-body'>
          <h4>
            {name} {lastName}
          </h4>
          <h6 className='name'>{email}</h6>
        </div>
        <div className='actions'>
          <Link className='btn' to={"./" + _id}>
            View
          </Link>
        </div>
      </div>
    </Col>
  );
};
export default GuestVisitCard;
