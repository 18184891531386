import { Container, Row } from "reactstrap";
import Wrapper from "../assets/wrappers/RestaurantsContainer";
import { useRestaurantContext } from "../pages/Restaurants/Restaurants";
import Restaurant from "./Restaurant";

const RestaurantsContainer = () => {
  const { data } = useRestaurantContext();
  const { restaurants } = data;
  if (restaurants.length === 0) {
    return (
      <Wrapper>
        <h2>No restaurants in your area</h2>
      </Wrapper>
    );
  }
  return (
    <div className='section'>
      <Container>
        <Row>
          {restaurants.map((restaurant) => {
            return <Restaurant key={restaurant._id} {...restaurant} />;
          })}
        </Row>
      </Container>
    </div>
  );
};
export default RestaurantsContainer;
