import { FaSuitcaseRolling, FaCalendarCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import customFetch from "../utils/customFetch";
import Wrapper from "../assets/wrappers/StatsContainer";
import { toast } from "react-toastify";
import { StatItem } from "../components";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const fetchAdminStats = async () => {
  const response = await customFetch.get("/users/admin/app-stats");
  return response.data;
};

const Admin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery("adminStats", fetchAdminStats, {
    onError: (error) => {
      toast.error(t("toast.notAuthorized"));
      navigate("/dashboard");
    },
  });

  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  if (isError) {
    return <div>{t("error.loadingStatistics")}</div>;
  }

  const { users, jobs } = data;

  return (
    <Wrapper>
      <StatItem
        title={t("admin.currentUsers")}
        count={users}
        color='#e9b949'
        bcg='#fcefc7'
        icon={<FaSuitcaseRolling />}
      />
      <StatItem
        title={t("admin.totalJobs")}
        count={jobs}
        color='#657acb'
        bcg='#e0e8f9'
        icon={<FaCalendarCheck />}
      />
    </Wrapper>
  );
};

export default Admin;
