import styled from "styled-components";

const Wrapper = styled.div`
  .btn {
    cursor: pointer;
    color: var(--white);
    background: var(--primary-500);
    border: transparent;
    border-radius: var(--border-radius);
    letter-spacing: var(--letter-spacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
  }

  .btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
  }

  .btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
  }

  .btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
  }

  .btn-block {
    width: 100%;
  }

  button:disabled {
    cursor: wait;
  }

  .danger-btn {
    color: var(--red-dark);
    background: var(--red-light);
  }

  .danger-btn:hover {
    color: var(--white);
    background: var(--red-dark);
  }

  .btn-secondary {
    background: var(--secondary-500);
  }
`;
export default Wrapper;
