/*eslint-disable*/
import React from "react";
import { FaHeart } from "react-icons/fa6";

// reactstrap components
import { Container } from "reactstrap";

// core components

const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <Container>
          <div className='copyright' id='copyright'>
            © {new Date().getFullYear()}, Made with <FaHeart /> in Barcelona
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
