import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

const DropdownScrollNavbar = () => {
  const { t, i18n } = useTranslation(); // Translation hook

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [buyButtonColor, setBuyButtonColor] = useState("neutral");
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else {
        setNavbarColor("navbar-transparent");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const languageLabel = {
    en: "English",
    es: "Español",
    ca: "Català",
  };

  return (
    <>
      {collapseOpen && (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      )}
      <Navbar className={`fixed-top ${navbarColor}`} color='white' expand='lg'>
        <Container>
          <UncontrolledDropdown className='button-dropdown'>
            <DropdownToggle
              caret
              tag='a'
              data-toggle='dropdown'
              href='#pablo'
              id='navbarDropdown'
              onClick={(e) => e.preventDefault()}
            >
              <span className='button-bar'></span>
              <span className='button-bar'></span>
              <span className='button-bar'></span>
            </DropdownToggle>
            <DropdownMenu aria-labelledby='navbarDropdown'>
              <DropdownItem tag={Link} to='/register'>
                {t("register.title")}
              </DropdownItem>
              <DropdownItem tag={Link} to='/login'>
                {t("login.title")}
              </DropdownItem>
              <DropdownItem tag={Link} to='/restaurants'>
                {t("restaurantSignup.signup.title")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className='navbar-translate'>
            <NavbarBrand to='/' tag={Link} id='navbar-brand'>
              {t("navbar.brand")}
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className='navbar-toggler'
            >
              <span className='navbar-toggler-bar top-bar'></span>
              <span className='navbar-toggler-bar middle-bar'></span>
              <span className='navbar-toggler-bar bottom-bar'></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className='ml-auto' id='ceva' navbar>
              <NavItem>
                <Link
                  to='/register'
                  className='nav-link btn-default'
                  color={buyButtonColor}
                >
                  <p>{t("register.title")}</p>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to='/login'
                  className='nav-link btn-default'
                  color={buyButtonColor}
                >
                  <p>{t("login.title")}</p>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to='/restaurants'
                  className='nav-link btn-default'
                  color={buyButtonColor}
                >
                  <p>{t("restaurantSignup.title")}</p>
                </Link>
              </NavItem>
              {/* Language picker dropdown */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {languageLabel[currentLanguage]}
                </DropdownToggle>
                <DropdownMenu right>
                  {Object.keys(languageLabel).map((lng) => (
                    <DropdownItem key={lng} onClick={() => changeLanguage(lng)}>
                      {languageLabel[lng]}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default DropdownScrollNavbar;
