import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Wrapper from "../assets/wrappers/Reward";
import day from "dayjs";

const Reward = ({ campaign, restaurant, validFrom, expiry }) => {
  const { t } = useTranslation();

  const startDate = day(validFrom).format("DD/MM/YYYY");
  const expiryDate = day(expiry).format("DD/MM/YYYY");

  return (
    <div className='col-md-6 col-lg-4'>
      <div className='card'>
        <div className='card-body'>
          <header>
            <div className='info'>
              <h4>{restaurant.name}</h4>
              <h4>{campaign.reward.name}</h4>
            </div>
          </header>
          <div className='content'>
            <div className='content-center'>
              <div className='description'>
                {campaign.description}
                <div className='validity'>
                  <span className='valid-from'>
                    {t("labels.validFrom")}: {startDate}
                  </span>{" "}
                  &nbsp;
                  <span className='valid-to'>
                    {t("labels.expiresAt")}: {expiryDate}
                  </span>
                </div>
              </div>
            </div>
            <div className='card-footer'>
              <Link
                className='btn'
                to={`/dashboard/restaurants/${restaurant._id}`}
              >
                {t("buttons.viewRestaurant")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reward;
