import { Card, Col } from "reactstrap";
import defaultAvatar from "../../assets/images/placeholder.jpg";
import "./Cards.css";
import { useTranslation } from "react-i18next";

const CampaignCard = ({
  name,
  type,
  description,
  criteria,
  avatar,
  actions,
}) => {
  const { t } = useTranslation();
  return (
    <Col className='col-md-6'>
      <Card className='card card-profile'>
        <div className='card-user'>
          <img src={avatar || defaultAvatar} alt='staff' />
        </div>
        <div className='card-body'>
          <div className='react-tagsinput'>
            <span className='react-tags input-tag badge badge-primary pull-left'>
              {type}
            </span>
          </div>

          <h4 className='card-title'>{name}</h4>
          <p className='card-description'>
            {description} {t("component.campaigncard.criteriaDescription")}
            {criteria.visits}
          </p>
        </div>
        <div className='card-footer'>{actions}</div>
      </Card>
    </Col>
  );
};
export default CampaignCard;
