import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import customFetch from "../../utils/customFetch";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import {
  DashboardPageHeader,
  Footer,
  ImageUpload,
  InputWithIcon,
} from "../../components";
import { FaUser, FaEnvelope, FaCity, FaUserEdit } from "react-icons/fa";
import "./Profile.css";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { user } = useOutletContext();
  const { name, lastName, email, address, language } = user;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: name || "",
    lastName: lastName || "",
    email: email || "",
    city: address.city || "",
    language: language || i18n.language || "en", // Default to English if no language set
  });
  const [focusState, setFocusState] = useState({
    nameFocus: false,
    lastNameFocus: false,
    emailFocus: false,
    cityFocus: false,
    languageFocus: false,
  });
  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    document.body.classList.add("profile-page", "sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return () => {
      document.body.classList.remove("profile-page", "sidebar-collapse");
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAvatarChange = (file) => {
    if (file && file.size > 5000000) {
      toast.error(t("imageSizeError"));
      return;
    }
    setAvatarFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, lastName, email, city, language } = formData;

    if (!name || !lastName || !email || !city) {
      toast.error(t("allFieldsRequiredError"));
      return;
    }

    try {
      const formDataObj = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataObj.append(key, value);
      });
      if (avatarFile) {
        formDataObj.append("avatar", avatarFile);
      }

      await customFetch.patch("/users/update-user", formDataObj);
      queryClient.invalidateQueries(["user"]);
      toast.success(t("profileUpdateSuccess"));

      navigate("/dashboard/");
    } catch (error) {
      toast.error(error?.response?.data?.msg || t("genericError"));
    }
  };

  const renderInputWithIcon = (name, placeholderKey, icon, focusKey) => (
    <InputWithIcon
      type='text'
      name={name}
      placeholder={t(placeholderKey)}
      value={formData[name]}
      onChange={handleChange}
      icon={icon}
      focusState={{
        focus: focusState[focusKey],
        setFocus: (focus) =>
          setFocusState({ ...focusState, [focusKey]: focus }),
      }}
    />
  );

  const renderLanguageSelector = () => (
    <div className='form-group'>
      <label htmlFor='language'>{t("language")}</label>
      <select
        id='language'
        name='language'
        className='form-control'
        value={formData.language}
        onChange={handleChange}
        onFocus={() => setFocusState({ ...focusState, languageFocus: true })}
        onBlur={() => setFocusState({ ...focusState, languageFocus: false })}
      >
        <option value='ca'>{t("languages.catalan")}</option>
        <option value='en'>{t("languages.english")}</option>
        <option value='es'>{t("languages.spanish")}</option>
      </select>
    </div>
  );

  return (
    <>
      <DashboardPageHeader pageTitle={t("profile")} />

      <div className='section'>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto' md='5'>
              <Card className='card-profile card-plain'>
                <Form
                  onSubmit={handleSubmit}
                  className='form'
                  encType='multipart/form-data'
                >
                  <div className='container'>
                    <ImageUpload
                      avatar
                      currentImage={user.avatar}
                      onImageChange={handleAvatarChange}
                    />
                  </div>
                  <CardHeader className='text-center'></CardHeader>
                  <CardBody>
                    {renderInputWithIcon(
                      "name",
                      "name",
                      <FaUser />,
                      "nameFocus"
                    )}
                    {renderInputWithIcon(
                      "lastName",
                      "lastName",
                      <FaUserEdit />,
                      "lastNameFocus"
                    )}
                    {renderInputWithIcon(
                      "email",
                      "email",
                      <FaEnvelope />,
                      "emailFocus"
                    )}
                    {renderInputWithIcon(
                      "city",
                      "city",
                      <FaCity />,
                      "cityFocus"
                    )}
                    {renderLanguageSelector()}
                  </CardBody>
                  <CardFooter className='text-center'>
                    <Button
                      block
                      className='btn-round'
                      color='info'
                      size='lg'
                      type='submit'
                    >
                      {t("update")}
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Profile;
