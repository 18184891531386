import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerUser as registerService } from "../services/authService";

const useRegister = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const register = async (data) => {
    setLoading(true);
    try {
      await registerService(data);
      toast.success("Registration successful");
      navigate("/login");
    } catch (error) {
      toast.error(error?.response?.data?.msg || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  return { register, loading };
};

export default useRegister;
