import React from "react";
import { Container, Row, Col } from "reactstrap";

const Terms = () => {
  return (
    <Container className='terms-conditions'>
      <Row>
        <Col>
          <h1>Terms and Conditions</h1>
          <p>Last updated: [Date]</p>

          <h2>1. Introduction</h2>
          <p>
            Welcome to DinnerChamp! These terms and conditions outline the rules
            and regulations for the use of DinnerChamp's Website and services,
            located at www.dinnerchamp.com.
          </p>

          <h2>2. User Accounts</h2>
          <p>
            By creating an account with us, you guarantee that you are above the
            age of 18 and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on our service.
          </p>
          <p>
            During registration, we will collect your email, city, and name.
            This information is necessary for providing you with personalized
            services and managing your account.
          </p>

          <h2>3. User Responsibilities</h2>
          <p>
            You agree to use our service responsibly and to respect the
            limitations set by DinnerChamp. Users are limited to one check-in
            per restaurant per day. While you can freely use the product to
            receive rewards, you must not engage in fraudulent or abusive
            behavior.
          </p>
          <p>
            Please note that restaurants are responsible for fulfilling rewards.
            DinnerChamp does not take responsibility for the fulfillment of
            rewards by restaurants.
          </p>

          <h2>4. Payment and Fees</h2>
          <p>
            For users, the service is free of charge. Restaurants are subject to
            subscription fees, which are detailed in our separate restaurant
            terms.
          </p>

          <h2>5. Privacy and Data</h2>
          <p>
            Your privacy is important to us. We will store your data securely in
            our database and will not use it without your consent. We do not
            collect unnecessary or sensitive information. For more details,
            please refer to our Privacy Policy.
          </p>

          <h2>6. Intellectual Property</h2>
          <p>
            The content on the platform is owned by the user who created it.
            Currently, only restaurants can upload and share content. In the
            future, users may have the ability to add reviews. Users must ensure
            that any content they provide does not violate any third-party
            rights or applicable laws.
          </p>

          <h2>7. Limitation of Liability</h2>
          <p>
            DinnerChamp is a platform to manage relationships between
            restaurants and their guests. We are not liable for the actions of
            either party. However, we will aim to resolve any disagreements that
            may arise. For users, there is no inherent risk in using our
            service. Any disputes regarding rewards must be resolved directly
            with the restaurant.
          </p>

          <h2>8. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of Spain. Any disputes arising under or in connection with
            these Terms shall be subject to the exclusive jurisdiction of the
            courts located in Barcelona, Spain.
          </p>

          <h2>9. Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will try to
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>

          <h2>10. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at
            hello@dinnerchamp.com.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
