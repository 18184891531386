import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Restaurant = ({
  _id,
  name,
  city,
  buildingNumber,
  postcode,
  street,
  mainImage,
}) => {
  const { t } = useTranslation();
  return (
    <div className='col-md-6 col-lg-4'>
      <div className='card-product card'>
        <div className='card-image'>
          <Link to={"./" + _id}>
            <img src={mainImage ? mainImage : "/restaurant-default.png"} />
          </Link>
        </div>
        <div className='card-body'>
          <Link to={"./" + _id}>
            <h4 className='card-title'>{name}</h4>
          </Link>
          <p className='card-description'>
            <span className='street'>{street}</span>
            <span className='number'> {buildingNumber}</span>
            <div className='city'>{city}</div>
            <div className='postcode'>{postcode}</div>
          </p>
          <div className='card-footer'>
            <Link className='btn' to={"./" + _id}>
              {t("buttons.view")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Restaurant;
