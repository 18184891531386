import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import defaultImage from "../../assets/images/image_placeholder.jpg";
import defaultAvatar from "../../assets/images/placeholder.jpg";

const ImageUpload = ({ avatar, currentImage, onImageChange }) => {
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    currentImage ? currentImage : defaultAvatar
  );
  const fileInput = useRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      if (onImageChange) {
        onImageChange(file); // Pass the selected file back to the parent component
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(currentImage ? currentImage : defaultImage);
    fileInput.current.value = null;
    if (onImageChange) {
      onImageChange(null); // Inform the parent component that the file has been removed
    }
  };

  return (
    <div className='fileinput text-center'>
      <input
        type='file'
        onChange={handleImageChange}
        ref={fileInput}
        style={{ display: "none" }}
      />
      <div
        className={`photo-container fileinput-new img-raised ${avatar ? "img-circle" : ""}`}
      >
        <img src={imagePreviewUrl} alt='...' />
      </div>
      <div>
        {file === null ? (
          <Button className='btn-round' color='default' onClick={handleClick}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button className='btn-round' color='default' onClick={handleClick}>
              Change
            </Button>
            {avatar && <br />}
            <Button color='danger' className='btn-round' onClick={handleRemove}>
              <i className='fa fa-times' /> Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  currentImage: PropTypes.string, // Assuming currentImage is a URL string
  onImageChange: PropTypes.func.isRequired,
};

export default ImageUpload;
