import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
} from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import customFetch from "../../utils/customFetch";
import { useDashboardContext } from "../../pages/DashboardLayout/DashboardLayout";
import { DashboardPageHeader, FormInput, SubmitBtn } from "../../components";

const Refer = () => {
  const { t } = useTranslation();
  const { user } = useDashboardContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await customFetch.get("/restaurants");
        setRestaurants(response.data.restaurants);
      } catch (error) {
        toast.error(t("Failed to load restaurants"));
      }
    };

    fetchRestaurants();
  }, [t]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRestaurantChange = (e) => {
    setSelectedRestaurant(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedRestaurant) {
      toast.error(t("Please select a restaurant"));
      return;
    }

    const data = { email, restaurant: selectedRestaurant };

    try {
      await customFetch.post(`/referral`, data);
      toast.success(t("Referral sent successfully"));
      navigate("/dashboard");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.msg || t("An error occurred"));
      } else {
        toast.error(t("An error occurred"));
      }
    }
  };

  return (
    <>
      <DashboardPageHeader pageTitle={t("Refer")} />
      <Container>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='card-profile card-plain'>
              <CardHeader>
                <h4 className='card-title'>{t("Refer a Friend")}</h4>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormInput
                    type='email'
                    name='email'
                    value={email}
                    onChange={handleEmailChange}
                    labelText={t("Friend's Email")}
                    placeholder={t("Enter your friend's email")}
                    required
                  />
                  <div className='form-group'>
                    <select
                      className='form-control'
                      id='restaurant'
                      value={selectedRestaurant}
                      onChange={handleRestaurantChange}
                      required
                    >
                      <option value=''>{t("Select a restaurant")}</option>
                      {restaurants.map((restaurant) => (
                        <option key={restaurant._id} value={restaurant._id}>
                          {restaurant.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <SubmitBtn formBtn>{t("Refer")}</SubmitBtn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Refer;
