import { useState, useEffect, createContext, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import customFetch from "../../utils/customFetch";
import { Loading } from "../../components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const OnboardingContext = createContext();

const OnboardingLayout = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await customFetch.get("/users/current-user");
        setUser(data.user);

        if (data.user.isOnboardingComplete) {
          navigate("/dashboard");
        } else {
          // Redirect to the first incomplete step
          if (!data.user.isOnboardingComplete) navigate("/onboarding/");
        }
      } catch (error) {
        toast.error(error?.response?.data?.msg || t("toast.errorOccurred"));
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [navigate, t]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <OnboardingContext.Provider
      value={{
        user,
      }}
    >
      <Outlet context={{ user }} />
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
export default OnboardingLayout;
