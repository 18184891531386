import { Outlet } from "react-router-dom";
import { DropdownScrollNavbar } from "../../components";

const HomeLayout = () => {
  return (
    <>
      <DropdownScrollNavbar />
      <Outlet className='wrapper' />
    </>
  );
};
export default HomeLayout;
