import "./FormInput.css";

const FormInput = ({
  type,
  name,
  labelText,
  defaultValue,
  onChange,
  display,
}) => {
  return (
    <div className={`form-row ${display ? display : ""} form-floating`}>
      <input
        type={type}
        id={name}
        name={name}
        className='form-control'
        defaultValue={defaultValue || ""}
        onChange={onChange}
        required
      />
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>
    </div>
  );
};
export default FormInput;
