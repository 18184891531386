import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LandingPageHeader, Footer } from "../../components";

const RestaurantSignupPage = () => {
  const { t } = useTranslation();

  return (
    <div className='wrapper'>
      <LandingPageHeader />

      <div className='section section-about-us'>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <h2 className='title'>{t("restaurantSignup.grow.title")}</h2>
              <h5 className='description'>
                {t("restaurantSignup.grow.description")}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md='4'>
              <Card className='card-pricing card-plain'>
                <CardBody>
                  <CardTitle tag='h6'>
                    {t("restaurantSignup.loyalty.title")}
                  </CardTitle>
                  <CardText>
                    {t("restaurantSignup.loyalty.description")}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card className='card-pricing card-plain'>
                <CardBody>
                  <CardTitle tag='h6'>
                    {t("restaurantSignup.referral.title")}
                  </CardTitle>
                  <CardText>
                    {t("restaurantSignup.referral.description")}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card className='card-pricing card-plain'>
                <CardBody>
                  <CardTitle tag='h6'>
                    {t("restaurantSignup.analytics.title")}
                  </CardTitle>
                  <CardText>
                    {t("restaurantSignup.analytics.description")}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='section section-testimonials'>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <h2 className='title'>
                {t("restaurantSignup.testimonials.title")}
              </h2>
              <Card className='card-testimonial'>
                <CardBody>
                  <CardText>
                    {t("restaurantSignup.testimonials.quote")}
                  </CardText>
                  <CardTitle tag='h4'>
                    {t("restaurantSignup.testimonials.author")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='section section-contact-us text-center'>
        <Container>
          <h2 className='title'>{t("restaurantSignup.signup.title")}</h2>
          <p className='description'>
            {t("restaurantSignup.signup.description")}
          </p>
          <Button
            className='btn-round'
            color='info'
            href='#'
            onClick={(e) => e.preventDefault()}
          >
            {t("restaurantSignup.signup.button")}
          </Button>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default RestaurantSignupPage;
