import { createContext, useContext } from "react";
import { DashboardPageHeader, RestaurantsContainer } from "../../components";
import customFetch from "../../utils/customFetch";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

// Define translation keys
const translations = {
  loading: "Loading...",
  error: "Error loading restaurants data.",
};

// Define the query for fetching restaurants
const RestaurantsQuery = {
  queryKey: ["restaurants"],
  queryFn: async () => {
    const response = await customFetch.get("/restaurants");
    return response.data;
  },
};

// Create a context for Restaurants
const RestaurantContext = createContext();

// The RestaurantList component
const RestaurantList = () => {
  const { t } = useTranslation();

  // Use the useQuery hook to fetch restaurants
  const { data, isLoading, isError } = useQuery(RestaurantsQuery);

  // Handle loading and error states using translated strings
  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  if (isError) {
    return <div>{t("error")}</div>;
  }

  // Provide the data via context
  return (
    <RestaurantContext.Provider value={{ data }}>
      <DashboardPageHeader pageTitle={t("restaurants.pageTitle")} />
      <RestaurantsContainer />
    </RestaurantContext.Provider>
  );
};

// Custom hook to use RestaurantContext
export const useRestaurantContext = () => useContext(RestaurantContext);

export default RestaurantList;
