import customFetch from "../utils/customFetch";

export const loginUser = async (data) => {
  return await customFetch.post("/auth/login", data);
};

export const registerUser = async (data) => {
  return await customFetch.post("/auth/register", data);
};

export const logoutUser = async () => {
  return await customFetch.get("/auth/logout");
};

// Add more authentication-related functions as needed
