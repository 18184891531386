import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import { Footer, InputWithIcon } from "../../components";
import { MdOutlineLock } from "react-icons/md";
import logoImage from "../../assets/images/logo.svg";

const UpdatePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    document.body.classList.add("login-page", "sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return () => {
      document.body.classList.remove("login-page", "sidebar-collapse");
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const response = await fetch("/api/v1/auth/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, newPassword: password }),
      });

      if (response.ok) {
        setMessage("Password successfully reset. Please log in.");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setMessage("Failed to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  return (
    <>
      <div
        className='page-header header-filter'
        style={{
          background:
            "linear-gradient(0deg, rgba(44,44,44,.2), rgba(3,161,224,.6))",
        }}
      >
        <div className='page-header-image'></div>
        <div className='content'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' md='5'>
                <Card className='card-login card-plain'>
                  <Form onSubmit={handleSubmit} className='form'>
                    <CardHeader className='text-center'>
                      <div className='logo-container'>
                        <img alt='Company Logo' src={logoImage}></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputWithIcon
                        type='password'
                        name='password'
                        placeholder={t("login.newPassword")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        icon={<MdOutlineLock />}
                        focusState={{
                          focus: passwordFocus,
                          setFocus: setPasswordFocus,
                        }}
                      />
                      <InputWithIcon
                        type='password'
                        name='confirmPassword'
                        placeholder={t("login.confirmPassword")}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        icon={<MdOutlineLock />}
                        focusState={{
                          focus: confirmPasswordFocus,
                          setFocus: setConfirmPasswordFocus,
                        }}
                      />
                    </CardBody>
                    <CardFooter className='text-center'>
                      <Button
                        block
                        className='btn-round'
                        color='info'
                        size='lg'
                        type='submit'
                        disabled={loading}
                      >
                        {loading
                          ? t("login.loading")
                          : t("login.resetPassword")}
                      </Button>
                      {message && <p>{message}</p>}
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UpdatePassword;
