import { useRouteError, Link } from "react-router-dom";
import Wrapper from "../../assets/wrappers/ErrorPage";
import img from "../../assets/images/not-found.svg";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Error = () => {
  const { t } = useTranslation(); // Access translation functions
  const error = useRouteError();

  if (error.status === 404) {
    return (
      <Wrapper>
        <div>
          <img src={img} alt='not found' />
          <h3>{t("errors.pageNotFound.title")}</h3> {/* Translate title */}
          <p>{t("errors.pageNotFound.description")}</p>{" "}
          {/* Translate description */}
          <Link to='/dashboard'>{t("buttons.backHome")}</Link>{" "}
          {/* Translate link text */}
        </div>
      </Wrapper>
    );
  }

  return (
    <div>
      <h3>{t("errors.generic")}</h3> {/* Translate generic error message */}
    </div>
  );
};

export default Error;
