const DashboardPageHeader = ({ pageTitle, backgroundImage }) => {
  return (
    <div className='page-header page-header-mini'>
      <div
        className='page-header-image'
        style={
          backgroundImage
            ? { backgroundImage: `url(${backgroundImage})` }
            : null
        }
      ></div>
      <div className='content-center'>
        <div className='row'>
          <div className='ml-auto mr-auto col-md-8'>
            <h1>{pageTitle}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPageHeader;
