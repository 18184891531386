import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDashboardContext } from "../pages/DashboardLayout/DashboardLayout";

const withAuth = (Component) => {
  return (props) => {
    const { user } = useDashboardContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!user) {
        navigate("/login", { state: { from: location } });
      }
    }, [user, navigate, location]);

    if (!user) {
      return null; // or a loading spinner
    }

    return <Component {...props} />;
  };
};

export default withAuth;
