import { Link, useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../assets/wrappers/GuestVisit";
import customFetch from "../../utils/customFetch";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { DashboardPageHeader, FormRow, SubmitBtn } from "../../components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import "./GuestVisit.css";
import { Container, Row, Col } from "reactstrap";
import defaultAvatar from "../../assets/images/placeholder.jpg";

const UserQueryById = (id) => {
  return {
    queryKey: ["user", id],
    queryFn: async () => {
      const { data } = await customFetch.get(
        `/users/user-with-open-outcomes/${id}`
      );
      return data;
    },
  };
};

const GuestVisit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isError, isLoading } = useQuery(UserQueryById(id));
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);

  useEffect(() => {
    if (isError) {
      toast.error(t("errors.userFetch"));
      navigate("/dashboard/visits");
    }
  }, [isError, navigate, t]);

  if (isLoading) {
    return <div>{t("common:loading")}</div>;
  }

  const { user, openOutcomes } = data;

  const handleOutcomeSelect = (outcomeId) => {
    if (selectedOutcomes.includes(outcomeId)) {
      setSelectedOutcomes(selectedOutcomes.filter((id) => id !== outcomeId));
    } else {
      setSelectedOutcomes([...selectedOutcomes, outcomeId]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await customFetch.patch("/outcomes/redeem", {
        outcomeIds: selectedOutcomes,
      });
      toast.success(t("success.rewardRedeemed"));
      queryClient.invalidateQueries({ queryKey: ["guestVisits"] });
      navigate("/dashboard/checkin");
    } catch (error) {
      toast.error(error?.response?.data?.message || t("errors.generic"));
    }
  };
  console.log(user);
  return (
    <>
      <DashboardPageHeader
        pageTitle={
          user.name + " " + user.lastName + t("pages.guestvisit.title")
        }
      />
      <div className='main'>
        <Container>
          <Col>
            <Row>
              <div className='card-profile card'>
                <div className='card-avatar'>
                  <img
                    src={user.avatar ? user.avatar : defaultAvatar}
                    alt='User Avatar'
                  />
                </div>
                <div className='card-body'>
                  <h4>
                    {user.name} {user.lastName}
                  </h4>
                  <h6 className='name'>{user.email}</h6>
                  <form onSubmit={handleSubmit} className='form'>
                    <h4 className='form-title'>{t("labels.rewards")}</h4>
                    <div className='form-center'>
                      <fieldset>
                        <legend>{t("labels.selectRewards")}</legend>
                        <div className='rewards-container'>
                          {openOutcomes.map((outcome) => (
                            <div
                              key={outcome._id}
                              className={`reward-item ${
                                selectedOutcomes.includes(outcome._id)
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => handleOutcomeSelect(outcome._id)}
                            >
                              {outcome.reward.name}
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                    <SubmitBtn>{t("buttons.redeem")}</SubmitBtn>
                  </form>
                </div>
              </div>
              <div className='content'></div>
            </Row>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default GuestVisit;
