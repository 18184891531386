import React, { useState } from "react";
import { useDashboardContext } from "../pages/DashboardLayout/DashboardLayout";
import { FaUtensils } from "react-icons/fa";

const RestaurantSelector = () => {
  const { user, activeRestaurant, selectActiveRestaurant } =
    useDashboardContext();
  const userRestaurants = user.restaurants;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  if (userRestaurants.length === 0) {
    return null; // or some fallback UI if there are no restaurants
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleRestaurantChange = (restaurantId) => {
    selectActiveRestaurant(restaurantId); // Update activeRestaurant with the selected restaurant ID
    setDropdownOpen(false); // Close the dropdown after selection
  };

  const activeRestaurantData = userRestaurants.find(
    (restaurant) => restaurant._id === activeRestaurant
  );

  return (
    <li className={`dropdown nav-item ${dropdownOpen ? "show" : ""}`}>
      <a
        href='#'
        id='navbarDropdownMenuLink1'
        aria-haspopup='true'
        className='dropdown-toggle nav-link'
        aria-expanded={dropdownOpen}
        onClick={toggleDropdown}
      >
        <p>
          {activeRestaurantData ? activeRestaurantData.name : "Restaurants"}
        </p>
      </a>
      <div
        tabIndex='-1'
        role='menu'
        aria-labelledby='navbarDropdownMenuLink1'
        aria-hidden={!dropdownOpen}
        className={`dropdown-menu dropdown-menu-right ${dropdownOpen ? "show" : ""}`}
      >
        {userRestaurants.map((restaurant) => (
          <a
            key={restaurant._id}
            tabIndex='0'
            role='menuitem'
            className='dropdown-item'
            href='#'
            onClick={() => handleRestaurantChange(restaurant._id)}
          >
            {restaurant.name}
          </a>
        ))}
      </div>
    </li>
  );
};

export default RestaurantSelector;
