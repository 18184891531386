import { createContext, useContext } from "react";
import customFetch from "../../utils/customFetch";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import { DashboardPageHeader } from "../../components";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import "./Restaurant.css";

// Define the query for fetching a restaurant by ID
const RestaurantByIdQuery = (id) => ({
  queryKey: ["restaurant", id],
  queryFn: async () => {
    const { data } = await customFetch.get(`/restaurants/${id}`);
    return data;
  },
});

// Create a context for the restaurant data
const RestaurantByIdContext = createContext();

const Restaurant = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { id } = useParams(); // Use useParams to get the id from the URL
  const navigate = useNavigate(); // Use useNavigate for redirection

  // Use the useQuery hook to fetch restaurant data by ID
  const { data, isLoading, isError } = useQuery(RestaurantByIdQuery(id), {
    onError: (error) => {
      toast.error(error?.response?.data?.msg || t("errors.generic"));
      navigate("/dashboard/restaurants"); // Redirect on error
    },
  });

  // Handle loading state
  if (isLoading) {
    return <div>{t("common.loading")}</div>;
  }

  // Handle error state
  if (isError || !data?.restaurant) {
    return <div>{t("common.error")}</div>;
  }

  const { restaurant } = data;

  // Provide the data via context
  return (
    <RestaurantByIdContext.Provider value={{ restaurant }}>
      <DashboardPageHeader
        pageTitle={restaurant.name}
        backgroundImage={restaurant.mainImage}
      />
      <div className='section'>
        <Container>
          <Row>
            <Col md='6'>
              <p className='category'>{t("labels.restaurantAddress")}</p>

              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <p>
                    <strong>{t("myRestaurant.street")}:</strong>{" "}
                    {restaurant.street} {restaurant.buildingNumber}
                  </p>
                  <p>
                    <strong>{t("myRestaurant.city")}:</strong> {restaurant.city}
                  </p>
                  <p>
                    <strong>{t("myRestaurant.postcode")}:</strong>{" "}
                    {restaurant.postcode}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <p className='category'>{t("labels.reserveTable")}</p>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <Button
                    color='info'
                    tag={Link}
                    to={`/reserve/${restaurant._id}`}
                  >
                    {t("buttons.reserveNow")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <p className='category'>{t("labels.menus")}</p>

              <Card>
                <CardHeader>
                  <ul
                    data-background-color='blue'
                    role='tablist'
                    className='nav-tabs-neutral justify-content-center nav nav-tabs'
                  >
                    <li className='nav-item'>
                      <a href='#pablo' className='active nav-link'>
                        {t("labels.lunchtime")}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='#pablo' className='nav-link'>
                        {t("labels.evening")}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='#pablo' className='nav-link'>
                        {t("labels.wine")}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='#pablo' className='nav-link'>
                        {t("labels.drinks")}
                      </a>
                    </li>
                  </ul>
                </CardHeader>
                <CardBody>
                  <p>{t("labels.menusComingSoon")}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md='6'>
              <p className='category'>{t("labels.activeCampaigns")}</p>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <ListGroup>
                    {restaurant.campaigns.map((campaign) => (
                      <ListGroupItem key={campaign._id}>
                        {campaign.name} - {campaign.description}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
            <Col md='6'>
              <p className='category'>{t("labels.reviews")}</p>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <p>{t("labels.reviewsComingSoon")}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </RestaurantByIdContext.Provider>
  );
};

export const useRestaurantByIdContext = () => useContext(RestaurantByIdContext);
export default Restaurant;
