import React from "react";
import { IoBarChartSharp } from "react-icons/io5";
import { FaGift, FaBowlFood, FaPeopleGroup } from "react-icons/fa6";
import {
  MdAdminPanelSettings,
  MdHome,
  MdOutlineHome,
  MdOutlineRestaurant,
  MdTableRestaurant,
} from "react-icons/md";
import { FaUtensils } from "react-icons/fa";

const links = [
  // User context links
  {
    textKey: "myCode",
    path: "my-code",
    context: "user",
  },
  {
    textKey: "myrewards", // Translation key for "my rewards"
    path: "",
    context: "user",
  },
  {
    textKey: "referFriend", // Translation key for "refer a friend"
    path: "refer",
    context: "user",
  },
  {
    textKey: "restaurants", // Translation key for "restaurants"
    path: "restaurants",
    context: "user",
  },

  // Restaurant context links

  {
    textKey: "visits", // Translation key for "visits"
    path: "visits",
    roles: ["server", "admin"],
    context: "restaurant",
  },
  {
    textKey: "myrestaurant", // Translation key for "my restaurant"
    path: "my-restaurant/{_id}",
    roles: ["admin"],
    context: "restaurant",
  },
  /* {
    text: "campaign stats",
    path: "stats",
    icon: <IoBarChartSharp />,
    roles: ["admin"],
    context: "restaurant",
  },
 {
    text: "admin",
    path: "admin",
    icon: <MdAdminPanelSettings />,
    roles: ["admin"],
    context: "restaurant",
  },
   */
];

export default links;
