import React from "react";
import { Button, Container } from "reactstrap";
import "./Headers.css";
import { useTranslation } from "react-i18next";

const LandingPageHeader = () => {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current) {
          pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
        }
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <div className='page-header page-header-small'>
        <div className='page-header-image landing' ref={pageHeader}></div>
        <div className='content-center'>
          <Container>
            <h1 className='title'>{t("landing.header.title")}</h1>
            <h3 className='subtitle'>{t("landing.header.subtitle")}</h3>
          </Container>
        </div>
      </div>
    </>
  );
};

export default LandingPageHeader;
