import React from "react";
import { useTranslation } from "react-i18next";
import { useDashboardContext } from "../pages/DashboardLayout/DashboardLayout";
import links from "../utils/links";
import { NavLink as RouterNavLink } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

const NavLinks = ({ context }) => {
  const { t } = useTranslation(); // Translation hook
  const { user, activeRestaurant } = useDashboardContext();
  const { role } = user;

  // Filter links based on context and user's role
  const accessibleLinks = links.filter((link) => {
    if (link.context !== context) return false; // Check if link matches current context
    if (!link.roles) return true; // If no roles are specified, it's accessible to all
    return link.roles.includes(role);
  });

  return (
    <>
      {accessibleLinks.map(({ textKey, path, icon }) => {
        // Replace placeholder with actual activeRestaurant ID
        const actualPath = path.replace("{_id}", activeRestaurant);

        return (
          <NavItem key={textKey}>
            <NavLink to={actualPath} tag={RouterNavLink}>
              <span className='icon'>{icon}</span>
              {t(`links.${textKey}`)} {/* Fetch translated text */}
            </NavLink>
          </NavItem>
        );
      })}
    </>
  );
};

export default NavLinks;
