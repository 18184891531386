import React from "react";
import { useTranslation } from "react-i18next";
import { FaSquare, FaCheckSquare } from "react-icons/fa";

const SingleFormCheckbox = ({ checked, onChange, label }) => {
  const { t } = useTranslation();

  const handleCheckboxChange = () => {
    onChange(!checked); // Toggle the checked state and pass it back to parent
  };

  return (
    <div className='form-check mt-3' onClick={handleCheckboxChange}>
      <label className='form-check-label d-flex align-items-center'>
        {checked ? (
          <FaCheckSquare size={26} style={{ cursor: "pointer" }} />
        ) : (
          <FaSquare size={26} style={{ cursor: "pointer" }} />
        )}
        <span className='ml-2' style={{ cursor: "pointer" }}>
          {t(label)}
        </span>
      </label>
    </div>
  );
};

export default SingleFormCheckbox;
