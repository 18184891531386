import React from "react";
import { Input, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";

const InputWithIcon = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  icon,
  focusState,
}) => {
  const { focus, setFocus } = focusState;

  return (
    <InputGroup
      className={"no-border input-lg" + (focus ? " input-group-focus" : "")}
    >
      <InputGroupAddon addonType='prepend'>
        <InputGroupText>{icon}</InputGroupText>
      </InputGroupAddon>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </InputGroup>
  );
};

export default InputWithIcon;
