// ProfileContainer.js
import { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
} from "reactstrap";
import { FaUserCircle } from "react-icons/fa";
import { useDashboardContext } from "../../pages/DashboardLayout/DashboardLayout";
import { NavLink } from "react-router-dom";
import "./Containers.css";
import { useTranslation } from "react-i18next";

const ProfileContainer = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, logoutUser } = useDashboardContext();

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const { t } = useTranslation();

  return (
    <div className='profile-container'>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} nav inNavbar>
        <DropdownToggle nav caret className='nav-link'>
          {user.avatar ? (
            <img src={user.avatar} alt='avatar' className='img' />
          ) : (
            <FaUserCircle />
          )}
        </DropdownToggle>
        <DropdownMenu right>
          <NavItem className='dropdown-item'>
            <NavLink to='profile'>{t("links.profile")}</NavLink>
          </NavItem>
          <DropdownItem divider />
          <DropdownItem onClick={logoutUser}>{t("links.logout")}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ProfileContainer;
