import { Container, Row } from "reactstrap";
import Wrapper from "../assets/wrappers/VisitsContainer";
import { GuestVisitsContext } from "../pages/GuestVisits/GuestVisits";
import GuestVisitCard from "./GuestVisitCard";
//import Restaurant from "./Restaurant";

const GuestVisitsContainer = () => {
  const { validatedData } = GuestVisitsContext();

  if (validatedData.length === 0) {
    return (
      <>
        <h2>No checkins today</h2>
      </>
    );
  }

  return (
    <div className='section'>
      <Container>
        <Row>
          {validatedData.map((visit) => {
            return <GuestVisitCard key={visit._id} {...visit} />;
          })}
        </Row>
      </Container>
    </div>
  );
};

export default GuestVisitsContainer;
