import { Card, Col } from "reactstrap";
import defaultAvatar from "../../assets/images/placeholder.jpg";
import "./Cards.css";

const UserCard = ({ name, lastName, email, avatar, actions }) => {
  return (
    <Col>
      <Card className='card card-profile'>
        <div className='card-user'>
          <img src={avatar || defaultAvatar} alt='staff' />
        </div>
        <div className='card-body'>
          <h4 className='card-title'>
            {name} {lastName}
          </h4>
          <p className='card-description'>{email}</p>
        </div>
        <div className='card-footer'>{actions}</div>
      </Card>
    </Col>
  );
};
export default UserCard;
