import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../translations/en.json";
import es from "../translations/es.json";
import ca from "../translations/ca.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    ca: {
      translation: ca,
    },
  },
  lng: "es", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
