import { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, CardBody, Form } from "reactstrap";
import customFetch from "../../utils/customFetch";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import {
  SubmitBtn,
  InputWithIcon,
  ImageUpload,
  DashboardPageHeader,
  UserCard,
  CampaignCard,
} from "../../components";
import { useTranslation } from "react-i18next";
import { FaCity, FaEnvelope, FaUserEdit } from "react-icons/fa";

const fetchRestaurantById = async (id) => {
  const { data } = await customFetch.get(`/restaurants/${id}`);
  return data;
};

const fetchUsersByRestaurantId = async (id) => {
  const { data } = await customFetch.get(`/restaurants/${id}/users`);
  return data;
};

const fetchCampaignsByRestaurantId = async (id) => {
  const { data } = await customFetch.get(`/restaurants/${id}/campaigns`);
  return data;
};

const MyRestaurant = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const fileInputRef = useRef();

  // Fetching restaurant data
  const { data, isLoading, isError } = useQuery(["activeRestaurant", id], () =>
    fetchRestaurantById(id)
  );

  // Fetching users data
  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError,
  } = useQuery(["restaurantUsers", id], () => fetchUsersByRestaurantId(id));

  // Fetching campaigns data
  const {
    data: campaignsData,
    isLoading: campaignsLoading,
    isError: campaignsError,
  } = useQuery(["restaurantCampaigns", id], () =>
    fetchCampaignsByRestaurantId(id)
  );

  // State for form data
  const [formData, setFormData] = useState({
    street: "",
    buildingNumber: "",
    city: "",
    postcode: "",
    email: "",
    phone: "",
    googleMapsLink: "",
    mainImage: null, // Assuming this is where the main image file will be stored
  });

  // Mutation for updating restaurant data
  const updateRestaurantMutation = useMutation(
    async (formData) => {
      const file = formData.get("mainImage");

      if (file && file.size > 5000000) {
        throw new Error(t("common:imageSizeError"));
      }

      const res = await customFetch.patch(`/restaurants/${id}`, formData);

      if (res.status !== 200) {
        throw new Error(
          `${t("common:update")} ${t("common:errorLoadingData", { dataType: "restaurant profile" })}: ${res.status} ${res.statusText}`
        );
      }

      queryClient.invalidateQueries(["activeRestaurant", id]);

      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(t("common:restaurantProfileUpdatedSuccessfully"));
        console.log(data);
      },
      onError: (error) => {
        console.error(error);
        if (error.response) {
          const errorMessage =
            error.response.data?.msg || t("common:anErrorOccurred");
          toast.error(errorMessage);
        } else {
          toast.error(error.message);
        }
      },
    }
  );

  // Handler for form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    updateRestaurantMutation.mutate(formData);
  };

  // Avatar change handler
  const handleAvatarChange = (file) => {
    if (file && file.size > 5000000) {
      toast.error(t("common:imageSizeError"));
      return;
    }
    const newFormData = { ...formData };
    newFormData.mainImage = file; // Update the mainImage in formData
    setFormData(newFormData);
  };

  // Function to render input fields with icons
  const renderInputWithIcon = (name, placeholder, icon, focusKey) => (
    <InputWithIcon
      type='text'
      name={name}
      placeholder={t(`myRestaurant:${placeholder}`)}
      value={formData[name]}
      onChange={handleChange}
      icon={icon}
      focusState={{
        focus: true, // Placeholder for focus state management
        setFocus: () => {}, // Placeholder
      }}
    />
  );

  if (isLoading) {
    return <div>{t("common:loading")}</div>;
  }

  if (isError) {
    return (
      <div>{t("common:errorLoadingData", { dataType: "restaurant data" })}</div>
    );
  }

  const { restaurant } = data;
  return (
    <>
      <DashboardPageHeader pageTitle={restaurant.name} />
      <div className='section'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='8'>
              <Card>
                <Form
                  onSubmit={handleSubmit}
                  className='form'
                  encType='multipart/form-data'
                >
                  <CardBody>
                    <div className='mainImage-container'>
                      <ImageUpload
                        avatar={true} // Assuming you want an avatar-style upload
                        currentImage={restaurant.mainImage} // Assuming 'mainImage' is a URL string
                        onImageChange={handleAvatarChange}
                      />
                    </div>
                    <input
                      type='file'
                      accept='image/*'
                      id='mainImage'
                      name='mainImage'
                      ref={fileInputRef}
                      className='form-input'
                      hidden
                      onChange={(e) => handleAvatarChange(e.target.files[0])}
                    />
                    {renderInputWithIcon(
                      "street",
                      "street",
                      <FaCity />,
                      "streetFocus"
                    )}
                    {renderInputWithIcon(
                      "buildingNumber",
                      "building",
                      <FaCity />,
                      "buildingFocus"
                    )}
                    {renderInputWithIcon(
                      "city",
                      "city",
                      <FaCity />,
                      "cityFocus"
                    )}
                    {renderInputWithIcon(
                      "postcode",
                      "postcode",
                      <FaCity />,
                      "postcodeFocus"
                    )}
                    {renderInputWithIcon(
                      "email",
                      "email",
                      <FaEnvelope />,
                      "emailFocus"
                    )}
                    {renderInputWithIcon(
                      "phone",
                      "phone",
                      <FaUserEdit />,
                      "phoneFocus"
                    )}
                    {renderInputWithIcon(
                      "googleMapsLink",
                      "googleMapsLink",
                      <FaUserEdit />,
                      "googleMapsLinkFocus"
                    )}
                    <SubmitBtn formBtn>{t("common:update")}</SubmitBtn>
                  </CardBody>
                </Form>
              </Card>

              {/* Users Section */}
              <p className='category'>{t("common:staff")}</p>
              <Link
                className='btn btn'
                to={`/dashboard/my-restaurant/users/${restaurant._id}`}
              >
                {t("common:edit")}
              </Link>
              <Row>
                {usersLoading ? (
                  <div>{t("common:loading")}</div>
                ) : usersError ? (
                  <div>
                    {t("common:errorLoadingData", { dataType: "users" })}
                  </div>
                ) : (
                  <>
                    {usersData.users.map((user) => (
                      <UserCard
                        key={user.id}
                        name={user.name}
                        lastName={user.lastName}
                        email={user.email}
                        avatar={user.avatar}
                      />
                    ))}
                  </>
                )}
              </Row>

              {/* Campaigns Section */}
              <p className='category'>{t("common:campaigns")}</p>
              <Link
                className='btn btn'
                to={`/dashboard/my-restaurant/campaigns/${restaurant._id}`}
              >
                {t("common:edit")}
              </Link>
              <Row>
                {campaignsLoading ? (
                  <div>{t("common:loading")}</div>
                ) : campaignsError ? (
                  <div>
                    {t("common:errorLoadingData", { dataType: "campaigns" })}
                  </div>
                ) : (
                  <>
                    {campaignsData.campaigns.map((campaign) => (
                      <CampaignCard
                        key={campaign.id}
                        name={campaign.name}
                        type={campaign.type}
                        description={campaign.description}
                        criteria={campaign.criteria}
                      />
                    ))}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyRestaurant;
