import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Button,
} from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import customFetch from "../../utils/customFetch";
import { useDashboardContext } from "../../pages/DashboardLayout/DashboardLayout";
import Wrapper from "../../assets/wrappers/Checkin";
import { DashboardPageHeader, FormInput, SubmitBtn } from "../../components";

const Checkin = () => {
  const { t } = useTranslation();
  const { user, activeRestaurant } = useDashboardContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { email };

    try {
      await customFetch.post(`/guest-visits/create-guest-visit/${id}`, data);
      toast.success(t("toast.checkinSuccess"));
      queryClient.invalidateQueries({ queryKey: ["guestVisits"] });
      navigate("/dashboard/visits/");
    } catch (error) {
      toast.error(error?.response?.data?.msg || t("toast.checkinError"));
    }
  };

  return (
    <>
      <DashboardPageHeader pageTitle={Checkin} />
      <Container>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='card-profile card-plain'>
              <CardHeader>
                <h4 className='card-title'>{t("Checkin")}</h4>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormInput
                    type='text'
                    name='email'
                    value={email}
                    onChange={handleChange}
                    labelText={t("Email")}
                  />
                  <SubmitBtn formBtn>{t("Check in")}</SubmitBtn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Checkin;
