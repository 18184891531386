import { useNavigation } from "react-router-dom";
const SubmitBtn = ({ formBtn, intent, children }) => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <button
      type='submit'
      className={`btn btn-block ${formBtn && "form-btn"}`}
      disabled={isSubmitting}
      name='intent'
      value={intent || ""}
    >
      {isSubmitting ? "submitting" : children}
    </button>
  );
};
export default SubmitBtn;
