import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import ProfileContainer from "../Containers/ProfileContainer";
import RestaurantSelector from "../RestaurantSelector";
import NavLinks from "../NavLinks";
import { useDashboardContext } from "../../pages/DashboardLayout/DashboardLayout";

const LoggedInDropdownScrollNavbar = ({
  navbarColor = "navbar-transparent",
  buyButtonColor = "neutral",
}) => {
  const { user } = useDashboardContext();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [currentNavbarColor, setCurrentNavbarColor] = useState(navbarColor);
  const [currentBuyButtonColor, setCurrentBuyButtonColor] =
    useState(buyButtonColor);

  const [context, setContext] = useState(
    user.role === "admin" || user.role === "server" ? "restaurant" : "user"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleContextSwitch = (newContext) => {
    setContext(newContext);
    setDropdownOpen(false); // Close dropdown after selecting an option
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setCurrentNavbarColor("");
        setCurrentBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setCurrentNavbarColor("navbar-transparent");
        setCurrentBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  return (
    <>
      {collapseOpen && (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      )}
      <Navbar
        className={`fixed-top ${currentNavbarColor}`}
        color='white'
        expand='lg'
      >
        <Container>
          <NavbarBrand tag={Link} to='/dashboard' id='navbar-brand'>
            Dinnerchamp
          </NavbarBrand>
          <Button
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setCollapseOpen(!collapseOpen);
            }}
            aria-expanded={collapseOpen}
            className='navbar-toggler'
            color='link'
          >
            <span className='navbar-toggler-bar top-bar' />
            <span className='navbar-toggler-bar middle-bar' />
            <span className='navbar-toggler-bar bottom-bar' />
          </Button>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className='ml-auto' navbar>
              {user.role === "server" ||
                (user.role === "admin" && (
                  <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle nav caret>
                      {context === "user" ? "User" : "Restaurant"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleContextSwitch("user")}>
                        User
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => handleContextSwitch("restaurant")}
                      >
                        Restaurant
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ))}
              {context === "restaurant" && <RestaurantSelector />}
              <NavLinks context={context} />
              <ProfileContainer />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default LoggedInDropdownScrollNavbar;
