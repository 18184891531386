import { Card, CardHeader, Col, Row } from "reactstrap";
import Wrapper from "../assets/wrappers/RewardsContainer";
import { useMyRewardsContext } from "../pages/MyRewards/MyRewards";
import Reward from "./Reward";

const RewardsContainer = () => {
  const { data } = useMyRewardsContext();
  const { length } = data;
  if (length === 0) {
    return (
      <div className='container'>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h2>No current rewards</h2>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div className='container'>
      <div className='row'>
        {data.map((outcome) => {
          return <Reward key={outcome._id} {...outcome.entry} />;
        })}
      </div>
    </div>
  );
};
export default RewardsContainer;
