import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  HomeLayout,
  Landing,
  Register,
  Login,
  DashboardLayout,
  Error,
  Stats,
  Profile,
  Admin,
  MyRewards,
  Refer,
  RestaurantList,
  MyRestaurant,
  Restaurant,
  GuestVisit,
  Checkin,
  GuestVisits,
  RestaurantCampaigns,
  RestaurantUsers,
  OnboardingLayout,
  OnboardingStep1,
  RestaurantLanding,
  MyQRCode,
  QRCheckin,
  ForgottenPassword,
  UpdatePassword,
  Terms,
} from "./pages";
import ErrorElement from "./components/ErrorElement";
import useTheme from "./hooks/useTheme"; // Import the custom hook
import { lazy, Suspense } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

const LazyLanding = lazy(() => import("./pages/Landing/Landing"));
const LazyRegister = lazy(() => import("./pages/Register/Register"));
const LazyLogin = lazy(() => import("./pages/Login/Login"));
const LazyOnboardingStep1 = lazy(
  () => import("./pages/OnboardingStep1/OnboardingStep1")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyLanding />
          </Suspense>
        ),
      },
      {
        path: "restaurants",
        element: <RestaurantLanding />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgotten-password",
        element: <ForgottenPassword />,
      },
      {
        path: "update-password",
        element: <UpdatePassword />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
    ],
  },
  {
    path: "onboarding",
    element: <OnboardingLayout />,
    children: [
      {
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyOnboardingStep1 />
          </Suspense>
        ),
        index: true,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        element: <MyRewards />,
        index: true,
      },
      {
        path: "my-code",
        element: <MyQRCode />,
        errorElement: <ErrorElement />,
      },
      {
        path: "checkin/qr/:id",
        element: <QRCheckin />,
        errorElement: <ErrorElement />,
      },
      {
        path: "refer",
        element: <Refer />,
        errorElement: <ErrorElement />,
      },
      {
        path: "restaurants",
        element: <RestaurantList />,
        errorElement: <ErrorElement />,
      },
      {
        path: "restaurants/:id",
        element: <Restaurant />,
        errorElement: <ErrorElement />,
      },
      {
        path: "stats",
        element: <Stats />,
        errorElement: <ErrorElement />,
      },
      {
        path: "my-restaurant/:id",
        element: <MyRestaurant />,
        errorElement: <ErrorElement />,
      },
      {
        path: "my-restaurant/users/:id",
        element: <RestaurantUsers />,
        errorElement: <ErrorElement />,
      },
      {
        path: "my-restaurant/campaigns/:id",
        element: <RestaurantCampaigns />,
        errorElement: <ErrorElement />,
      },
      {
        path: "checkin/:id",
        element: <Checkin />,
        errorElement: <ErrorElement />,
      },
      {
        path: "visits",
        element: <GuestVisits />,
        errorElement: <ErrorElement />,
      },
      {
        path: "visits/:id",
        element: <GuestVisit />,
        errorElement: <ErrorElement />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
]);

const App = () => {
  const { isDarkTheme, toggleTheme } = useTheme(); // Use the custom hook
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
};

export default App;
