import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useRegister from "../../hooks/useRegister";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
} from "reactstrap";
import { Footer, InputWithIcon, SingleFormCheckbox } from "../../components";
import logoImage from "../../assets/images/logo.svg";
import { MdOutlineEmail, MdOutlineLock } from "react-icons/md";
import "./Register.css";

const Register = () => {
  const { t, i18n } = useTranslation();
  const { register, loading } = useRegister();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // State for terms acceptance
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  useEffect(() => {
    document.body.classList.add("login-page", "sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return () => {
      document.body.classList.remove("login-page", "sidebar-collapse");
    };
  }, []);

  const handleCheckboxChange = (isChecked) => {
    setTermsAccepted(isChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation: Ensure terms are accepted
    if (!termsAccepted) {
      alert(t("register.termsError"));
      return;
    }

    const language = i18n.language;
    const registrationDate = new Date().toISOString();
    register({ email, password, language, registrationDate });
  };

  return (
    <>
      <div
        className='page-header header-filter'
        style={{
          background:
            "linear-gradient(0deg, rgba(44,44,44,.2), rgba(3,161,224,.6))",
        }}
      >
        <div className='page-header-image'></div>
        <div className='content'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' md='5'>
                <Card className='card-login card-plain'>
                  <Form onSubmit={handleSubmit} className='form'>
                    <CardHeader className='text-center'>
                      <div className='logo-container'>
                        <img alt='Company Logo' src={logoImage}></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputWithIcon
                        type='email'
                        placeholder={t("register.email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        icon={<MdOutlineEmail />}
                        focusState={{
                          focus: emailFocus,
                          setFocus: setEmailFocus,
                        }}
                      />
                      <InputWithIcon
                        type='password'
                        placeholder={t("register.password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        icon={<MdOutlineLock />}
                        focusState={{
                          focus: passwordFocus,
                          setFocus: setPasswordFocus,
                        }}
                      />
                      <SingleFormCheckbox
                        checked={termsAccepted}
                        onChange={handleCheckboxChange}
                        label='register.termsAndConditionsCheck'
                      />
                      <a href='/terms' target='_blank'>
                        {t("register.termsAndCOnditions")}
                      </a>
                    </CardBody>
                    <CardFooter className='text-center'>
                      <Button
                        block
                        className='btn-round'
                        color='info'
                        size='lg'
                        type='submit'
                        disabled={loading}
                      >
                        {loading ? t("loading") : t("register.register")}
                      </Button>
                    </CardFooter>
                    <div className='pull-left'>
                      <h6>
                        <Link className='link footer-link' to='/login'>
                          {t("register.login")}
                        </Link>
                      </h6>
                    </div>
                    <div className='pull-right'>
                      <h6>
                        <Link className='link footer-link' to='/help'>
                          {t("register.help")}
                        </Link>
                      </h6>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Register;
