import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import customFetch from "../../utils/customFetch";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import {
  CampaignCard,
  DashboardPageHeader,
  InputWithIcon,
  SubmitBtn,
} from "../../components";
import { useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FaTrophy, FaPen, FaUsers } from "react-icons/fa";

const RestaurantQueryById = (id) => {
  return {
    queryKey: ["activeRestaurant", id],
    queryFn: async () => {
      const { data } = await customFetch.get(`/restaurants/${id}`);
      return data; // Return the entire data object
    },
  };
};

const createCampaign = async ({ id, campaignData }) => {
  await customFetch.post(`campaigns/create/${id}`, campaignData);
};

const RestaurantCampaigns = () => {
  const { t } = useTranslation();
  const { id } = useParams(); // Use useParams to get the id from the URL
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(RestaurantQueryById(id));
  const [campaignData, setCampaignData] = useState({
    type: "",
    name: "",
    description: "",
    reward: {
      name: "",
      description: "",
    },
    criteria: {
      visits: "",
    },
  });

  const createCampaignMutation = useMutation(createCampaign, {
    onSuccess: () => {
      queryClient.invalidateQueries(["activeRestaurant"]);
      toast.success(t("toast.campaignCreated"));
      setCampaignData({
        type: "",
        name: "",
        description: "",
        reward: {
          name: "",
          description: "",
        },
        criteria: {
          visits: "",
        },
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.msg || t("toast.errorOccurred"));
    },
  });

  const handleCreateCampaign = (e) => {
    e.preventDefault();
    createCampaignMutation.mutate({ id, campaignData });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "criteria-visits") {
      setCampaignData((prevData) => ({
        ...prevData,
        criteria: { visits: value }, // Update criteria.visits
      }));
    } else if (name.startsWith("reward-")) {
      const rewardField = name.split("reward-")[1];
      setCampaignData((prevData) => ({
        ...prevData,
        reward: {
          ...prevData.reward,
          [rewardField]: value,
        },
      }));
    } else {
      setCampaignData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  if (isError) {
    return <div>{t("errorLoadingRestaurantData")}</div>;
  }

  if (!data || !data.restaurant) {
    return <div>{t("noRestaurantDataAvailable")}</div>;
  }

  const restaurant = data.restaurant;

  return (
    <>
      <DashboardPageHeader pageTitle='Campaigns' />
      <div className='section'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='8'>
              <Card>
                <div className='card-body'>
                  <form onSubmit={handleCreateCampaign} className='form'>
                    <div className='form-center'>
                      <div className='form-row'>
                        <label htmlFor='type' className='form-label'>
                          {t("Type")}
                        </label>
                        <select
                          className='form-select'
                          id='type'
                          name='type'
                          value={campaignData.type}
                          onChange={handleChange}
                          required
                        >
                          <option value=''>{t("selectType")}</option>
                          <option value='loyalty'>{t("loyalty")}</option>
                          <option value='referral'>{t("referral")}</option>
                        </select>
                      </div>

                      <InputWithIcon
                        type='text'
                        name='name'
                        placeholder={t("Name")}
                        value={campaignData.name}
                        onChange={handleChange}
                        icon={<FaPen />}
                        focusState={{
                          focus: true, // Placeholder for focus state management
                          setFocus: () => {}, // Placeholder
                        }}
                      />
                      <InputWithIcon
                        type='text'
                        name='description'
                        placeholder={t("Description")}
                        value={campaignData.description}
                        onChange={handleChange}
                        icon={<FaPen />}
                        focusState={{
                          focus: true, // Placeholder for focus state management
                          setFocus: () => {}, // Placeholder
                        }}
                      />
                      <InputWithIcon
                        type='text'
                        name='reward-name'
                        placeholder={t("RewardName")}
                        value={campaignData.reward.name}
                        onChange={handleChange}
                        icon={<FaTrophy />}
                        focusState={{
                          focus: true, // Placeholder for focus state management
                          setFocus: () => {}, // Placeholder
                        }}
                      />
                      <InputWithIcon
                        type='text'
                        name='reward-description'
                        placeholder={t("RewardDescription")}
                        value={campaignData.reward.description}
                        onChange={handleChange}
                        icon={<FaTrophy />}
                        focusState={{
                          focus: true, // Placeholder for focus state management
                          setFocus: () => {}, // Placeholder
                        }}
                      />
                      {campaignData.type === "loyalty" && (
                        <InputWithIcon
                          type='number'
                          name='criteria-visits'
                          placeholder={t("NumberOfVisits")}
                          value={campaignData.criteria.visits}
                          onChange={handleChange}
                          icon={<FaUsers />}
                          focusState={{
                            focus: true, // Placeholder for focus state management
                            setFocus: () => {}, // Placeholder
                          }}
                        />
                      )}
                      <SubmitBtn type='submit' intent='add' formBtn>
                        {t("add")}
                      </SubmitBtn>
                    </div>
                  </form>
                </div>
              </Card>
            </Col>

            <section>
              <p className='category'>{t("Campaigns")}</p>

              <Row>
                {restaurant.campaigns.map((campaign) => (
                  <CampaignCard
                    key={campaign.id}
                    name={campaign.name}
                    type={campaign.type}
                    description={campaign.description}
                    criteria={campaign.criteria}
                  />
                ))}
              </Row>
            </section>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RestaurantCampaigns;
