import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { loginUser as loginService } from "../services/authService";

const useAuth = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = async (data, redirectTo = "/dashboard") => {
    setLoading(true);
    try {
      const response = await loginService(data);
      // Handle successful login here, e.g., storing tokens
      toast.success(t("toast.loginSuccessMessage")); // Use t function for translation
      navigate(redirectTo);
    } catch (error) {
      // toast.error(error?.response?.data?.msg || t("toast.loginFailedMessage")); // Use t function for translation -- Update error response to provide customised errors
      toast.error(t("toast.loginFailedMessage"));
    } finally {
      setLoading(false);
    }
  };

  return { login, loading };
};

export default useAuth;
