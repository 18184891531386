import { useOutletContext } from "react-router-dom";
import { DashboardPageHeader } from "../../components";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import { QRCode } from "react-qr-code";
import { useTranslation } from "react-i18next";

const MyQRCode = () => {
  const { t } = useTranslation();
  const { user } = useOutletContext();
  const userLink = `www.dinnerchamp.com/dashboard/checkin/qr/${user._id}`;
  return (
    <>
      <DashboardPageHeader pageTitle={t("my-code")} />

      <div className='main'>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto' md='5'>
              <Card className='card-profile card'>
                <CardBody>
                  <QRCode value={userLink} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default MyQRCode;
